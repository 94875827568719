<template>
  <div >
    <Header/> 
   
    <div class="svsvs">
        <div v-for="item in lesson_item_list" :key="item" style="width: 100%;height: 100.8px;">
        <router-link :to="{path:item.url+'/'+item.id}">
            <img :src="item.src" style="width:100%;height:100%;">
        </router-link>
        </div>
    <div style="width:100%;height:90px;"></div>
</div>

  </div>
</template>

<style>
.svsvs{
    height: auto;
    width: 100%;
    float:left;
    overflow: hidden auto;
}
</style>
<script>
import Header from "../Header";
export default {
  components:{Header},
  data(){
    return {
        lesson_item_list:[]
    }
  },
  created(){
       this.lesson_item_list = [{
              id:1,
              src:require('@/assets/s_topic/1.jpg'),
              url:'/s_topic'
            },
            {
             id:2,
              src:require('@/assets/s_topic/2.jpg'),
               url:'/s_topic'
            },
             {
              id:3,
              src:require('@/assets/s_topic/3.jpg'),
               url:'/s_topic'
            },{
              id:4,
              src:require('@/assets/s_topic/1.jpg'),
              url:'/s_topic'
            },
            {
             id:5,
              src:require('@/assets/s_topic/2.jpg'),
               url:'/s_topic'
            },
             {
              id:6,
              src:require('@/assets/s_topic/3.jpg'),
               url:'/s_topic'
            },]
  },
}
</script>

